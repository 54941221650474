import React from 'react'
import { ContainerHero, Hero, SvgImage } from "./style";

const HeroComponent: React.FC = () => {
  return (
    <>
    <Hero>
      <ContainerHero>
      </ContainerHero>
    </Hero>
    <SvgImage xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#7297a0" fillOpacity="1" d="M0,160L1440,96L1440,0L0,0Z"></path></SvgImage>
    </>
  )
}

export default HeroComponent;