import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const BoxContainer = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    maxHeight: '30rem',
    width: '25%',
    [theme.breakpoints.up('md')]: {
        width: '30%'
    },
    [theme.breakpoints.up('lg')]: {
        width: '25%'  
    },
    [theme.breakpoints.down('md')]: {
        width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
}));

export const Image = styled('img')(({ theme }) => ({
    width: 150,
    height: 150,
    padding: '0.5rem',
    objectFit: 'contain',
    zIndex: 1,
    transition: '1s ease-out 100ms',
    '&:hover': {
        transform: 'scale(1.08)',
    },
    [theme.breakpoints.down('sm')]: {
        width: 100,
        height: 100,
    },
}));

export const ProductCard = styled(Card)(({ theme }) => ({
    minWidth: '12rem', 
    minHeight: '12rem', 
    margin: '0.5rem',
    '&:hover': {
        boxShadow: "2px 2px 2px #000"
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: '45vw', 
    minHeight: '45vw', 
        margin: '0.5rem',
    },
}));


export const CodeBar = styled('div')(({ theme }) => ({
    padding: '0.3rem',
    borderBottomRightRadius: 20,
    fontSize: 12,
    fontWeight: 'bold',
    width: '9rem',
    backgroundColor: 'rgba(100,192,151,0.5)',
}));

export const PriceText = styled('div')(({ theme }) => ({
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: '0.5rem',
}));

export const UnitText = styled('div')(({ theme }) => ({
    fontSize: 12,
    marginBottom: '0.5rem',
    backgroundColor: 'rgba(100,192,151,0.5)',
    borderRadius: 20,
    padding: '0.3rem',
}));

export const FirstRow = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between'
}));
