import { styled } from '@mui/material/styles';

export const ContainerCategory = styled('div')(({ theme }) => ({
    width: '20%',
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        width: '30%',
    },
}));

export const ImageCategory = styled('img')(({ theme }) => ({
    width: '7rem',
    height: '7rem',
    borderRadius: '100px',
    transition: 'transform .3s ease-out',
    '&:hover': {
        transform: 'translate(10px, -10px)',  
        boxShadow: "2px 2px 2px #000"
    },
    [theme.breakpoints.down('md')]: {
        width: '4rem',
        height: '4rem',
    },
    [theme.breakpoints.up('md')]: {
        width: '5rem',
        height: '5rem',
    },
    [theme.breakpoints.up('lg')]: {
        width: '7rem',
        height: '7rem',
    },
    [theme.breakpoints.down('sm')]: {
        width: '3rem',
        height: '3rem',
    },
}));
