import React from 'react'
import HeroComponent from '../../components/Hero';
import FavoriteComponent from '../../components/Favorite';
import MyAccountComponent from '../../components/MyAccount';
import ExistingComponent from '../../components/Existing';
import AboutUsComponent from '../../components/AboutUs';
import Video from '../../components/Video';

const Home: React.FC = () => {
    
    return (
        <>
            <main style={{ overflow:"hidden" }}>
                <HeroComponent/>
                <FavoriteComponent/>
                <MyAccountComponent/>
                <ExistingComponent/>
                <AboutUsComponent/>
                <Video/>
            </main>
        </>
  )
}

export default Home;