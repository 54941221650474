import { styled } from '@mui/material/styles';

export const style = {
    animation: 'fadein 1s',    
    '@keyframes fadein': {
        'from': { 
            opacity: 0.1,
            filter: 'blur(4)' 
        },
        'to': { 
        opacity: 1, 
        filter: 'blur(0)' 
        }
    }
  };

export const ModalContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

export const ImageModal = styled('img')(({ theme }) => ({
    width: '40%',
    height: '80%',
    alignSelf: 'center',
    padding: '0.5rem',
    objectFit: 'contain',
    transition: '1s ease-out 100ms',
    '&:hover': {
        transform: 'scale(1.08)',
    },
}));

export const ProductInfo = styled('div')(({ theme }) => ({
    margin: '0.5rem',
    padding: '0.5rem',
    paddingTop: '1rem',
}));

export const PriceText = styled('div')(({ theme }) => ({
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginTop: '1rem'
}));

export const NameText = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
}));

export const Category = styled('div')(({ theme }) => ({
    padding: '0.3rem',
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomRightRadius: 20,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    width: '14rem',
    backgroundColor: 'rgba(100,192,151,0.5)',
}));

export const DataProduct = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2rem',
    padding: '1rem',
    margin: 0,
    backgroundColor: '#8acaff',
    flexDirection: 'column'
}));

export const DataContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem'
}));

export const DataPallet = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem'
}));

export const TextProduct = styled('p')(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: 0
}));