import React from 'react'
import { ProductModalProps } from '../../interfaces';
import { Category, DataContainer, DataPallet, DataProduct, ImageModal, ModalContainer, NameText, PriceText, ProductInfo, style, TextProduct } from './style';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';

const ProductModal: React.FC<ProductModalProps> = ({closeModal, showModal, product}) => {

    return (
        <Dialog open={showModal} onClose={closeModal} style={style} maxWidth='xs'>
            <DialogContent>
                <Category>
                    {'Category: ' + product.category.name}
                </Category>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <ModalContainer>
                    <ImageModal loading="lazy" src={`${product.image}`} />
                    <Divider sx={{height: '25rem', alignSelf: 'center'}} orientation="vertical" flexItem />
                    <ProductInfo>
                        <NameText>
                            {product.name}
                        </NameText>
                        <PriceText>
                            ${product.price.toFixed(2)}
                        </PriceText>
                        <Typography>Location: {product.binCode}</Typography>
                        <div style={{marginTop: '1rem', textAlign: 'center'}}>
                            <Typography>Code: {product.secondaryCode}</Typography>
                            <Typography>Secondary Code: {product.code}</Typography>
                        </div>
                        <DataContainer>
                            <DataProduct>
                                <TextProduct>{product.weight}</TextProduct>
                                <Typography>Weight</Typography>
                            </DataProduct>
                            <DataProduct>
                                <TextProduct>{product.volume}</TextProduct>
                                <Typography>Volume</Typography>
                            </DataProduct>
                        </DataContainer>
                        <DataPallet>
                            <DataProduct>
                                <TextProduct>{product.pallet}</TextProduct>
                                <Typography>Pallet</Typography>
                            </DataProduct>
                        </DataPallet>
                    </ProductInfo>
                </ModalContainer>
            </DialogContent>
        </Dialog>
        
    )
}

export default ProductModal;