import React from 'react'
import Typography from '@mui/material/Typography';
import { CategoryItemProps } from '../../interfaces';
import { ContainerCategory, ImageCategory } from './style';

const CategoryItem: React.FC<CategoryItemProps> = ({category, search}) => {

    return (
        <ContainerCategory onClick={() => search(category.id)}>
            <ImageCategory src={require(`../../assets/Images/Categories/${category.image}`)} />
            <Typography sx={{textAlign: 'center', fontSize: { xs: '10px', md: '16px'} }}>
                {category.name.toUpperCase()}
            </Typography>
        </ContainerCategory>
    )
}

export default CategoryItem