import { styled } from '@mui/material/styles';


export const Hero = styled('div')(({ theme }) => ({
    backgroundColor: '#7297a0',
    paddingBottom: '2rem',
    padding: '1rem',
    overflow: 'hidden',
    position: 'relative',
    zIndex: '2',
}));

export const ContainerHero = styled('div')(({ theme }) => ({
    height: '50vh',
    margin: 'auto',
    backgroundImage: `url(${'/hero2.png'})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        height: '60vh',
        backgroundImage: 'url("/heromob.png")',
        backgroundSize: 'contain',
    },
  }));

export const SvgImage = styled('svg')(({ theme }) => ({
    marginTop: '-6rem',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
        marginTop: '-8px',
        zIndex: '1',
    },
}));