import { styled } from '@mui/material/styles';
import { SocialIcon } from 'react-social-icons';

export const FooterApp = styled('div')(({ theme }) => ({
    color: 'white',
    fontSize: 'large',
    padding: '2rem 0rem',
    width: '100%',
    backgroundColor: 'rgb(212, 54, 49)',
}));

export const ContainerFooter = styled('div')(({ theme }) => ({
    width: '90%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
}));

export const HFooter = styled('h1')(({ theme }) => ({
    marginBottom: '1rem',
}));

export const IconSocial = styled(SocialIcon)(({ theme }) => ({
    margin: '0.5rem',
    borderRadius: '100px',
    '&:hover': {
        boxShadow: "2px 2px 2px #000"
    },
}));


