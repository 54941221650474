import { CardContent  } from '@mui/material';
import React from 'react'
import { ProductProps } from '../../interfaces';
import ProductModal from '../ProductModal';
import { BoxContainer, CodeBar, FirstRow, Image, PriceText, ProductCard, UnitText } from "./style";



const ProductComponent: React.FC<ProductProps> = ({product}) => {
    const [openModal, setOpenModal] = React.useState(false);

    const handleClick = () => {
        setOpenModal(!openModal);
    }

    return (
        <BoxContainer onClick={handleClick}>
            <ProductCard>
                <CodeBar>
                    Code: {product.secondaryCode}
                </CodeBar>
                <CardContent sx={{fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    
					<Image 
						loading="lazy" 
						src={`${product.image}`}
						onError={(e: any) => {
							e.target.onerror = null
							e.target.src = `https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg`
						}}
					/>
					
                    <div>
                        <FirstRow>
                            <PriceText>
                                ${product.price.toFixed(2)}
                            </PriceText>
                            <UnitText>
                                {product.unit.description}
                            </UnitText>
                        </FirstRow>
                        {product.name}
                    </div>
                </CardContent>
                <ProductModal product={product} showModal={openModal} closeModal={() => setOpenModal(false)} />
            </ProductCard>
        </BoxContainer>
    )
}

export default ProductComponent;