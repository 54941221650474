import axios, { AxiosError, AxiosResponse } from 'axios';

export const authApi = axios.create();

export const signUp = async (user: any) => {
  console.log("hola", user);
  let array = [];
  var theResp = "";
  array.push({
    u_CTS_FileName: user.file.fileName,
    u_CTS_FileExtension: user.file.fileExtension,
    u_CTS_File64: user.file.file
  });

  if (user.type === 'Export') {
    array.push({
      u_CTS_FileName: user.passport.passportName,
      u_CTS_FileExtension: user.passport.passportExtension,
      u_CTS_File64: user.passport.passport
    });
  }

  try {
    const resp: AxiosResponse = await authApi.post(
      `https://rexdiscount.idadns.com:33095/Api/BusinessPartner/CreateBusinessPartner`,
      {
        U_CTS_Mail: user.email,
        U_CTS_FName: user.name,
        U_CTS_LName: user.lastName,
        U_CTS_Pass: user.password,
        code: "88888",
        U_CTS_CardCode: "",
        U_CTS_Prefix: user.prefix,
        U_CTS_Country: user.country,
        U_CTS_Address: user.address,
        U_CTS_City: user.city,
        U_CTS_State: user.state,
        U_CTS_ZidCode: user.zipCode,
        U_CTS_BPhone: user.whatsapp,
        U_CTS_CellPhone: user.cellPhone,
        U_CTS_Fax: user.fax,
        U_CTS_Company: user.company,
        U_hear_about_us: user.hearAboutUs,
        U_CTS_JDescription: user.jobDescription,
        U_CTS_CompanyID: user.companyId,
        U_CTS_CompanyID_Exp: user.companyIdExp,
        Type: user.type,
        ctS_USERVENDOR2Collection: array
      }
    );
if (resp.data){
theResp = resp.status.toString();
return theResp;
}else{
	theResp="1";
	return theResp;
}
    
  } catch (error) {

	/*
    if (axios.isAxiosError(error)) {
      // Handle specific Axios errors
      const axiosError: AxiosError = error;
      const response = axiosError.response;

      if (response) {
        // The request was made and the server responded with a status code
        console.error('Error response data:', response.data);
        console.error('Status code:', response.status);
      } else {
        // The request was made but no response was received
        console.error('Error message:', error.message);
		theResp="2";
		return theResp;
      }
    } else {
      // Handle other errors
	  theResp="3";
	  return theResp;
      console.error('Error:', 'UFO');
    }*/
    if (axios.isAxiosError(error)) {
		const axiosError: AxiosError = error;
		const response = axiosError.response;
    // Access the JSON data if it exists
    const jsonData = axiosError.response?.data;
	//const jsonObject = JSON.parse(jsonData);
    if (error.response) {
		console.log('Server responded with status code:', error.response.status);
		console.log('Response data:', error.response.data.error.message);
		return error.response.data.error.message;
	  } else if (error.request) {
		console.log('No response received:', error.request);
		return 'No response received:';
	  } else {
		console.log('Error creating request:', error.message);
		return 'Error creating request:';
	  }
	//console.error('Error message:', axiosError.response?.data);
	return 'UFO';
}
	/*=resp.data;
	return theResp;*/
  }
};
