import React from 'react'
import { Item, ItemImage, MyAccountContainer, RightArrowDiv, RightArrowImage, Row, Title, TittleRow } from './style';


const MyAccountComponent: React.FC = () => {

    return (
        <MyAccountContainer>
            <TittleRow>
                <Title className={"title2"}>
                    New Accounts
                </Title>
            </TittleRow>
            <Row>
                <Item>
                    <ItemImage
                        style={{ borderRadius: "50%" }}
                        src={"/login.png"}
                        alt="Detailed description would go here."
                    />

                    <p style={{textAlign:"center", fontWeight:"bold"}}> Click Top Right <br /> SIGN UP</p>
                </Item>
                <RightArrowDiv>
                    <RightArrowImage />
                </RightArrowDiv>
                <Item>
                    <ItemImage
                        style={{ objectFit:"contain", width:"262px"}}
                        src={"/team.png"}
                        alt="Detailed description would go here."
                    />

                    <p style={{textAlign:"center", fontWeight:"bold"}}>Wait for team  to reach out and <br /> activate account</p>
                </Item>
                <RightArrowDiv>
                    <RightArrowImage />
                </RightArrowDiv>
                <Item>
                    <ItemImage
                        style={{ borderRadius: "999px" }}
                        src={"https://account.samsung.com/membership/assets/images/intro/intro_mbr.png"}
                        alt="Detailed description would go here."
                    />

                    <p style={{textAlign:"center", fontWeight:"bold"}}>Your account is now active. <br /> You are ready to sign in</p>
                </Item>
            </Row>
        </MyAccountContainer>
    )

}

export default MyAccountComponent;
