import { useEffect, useState } from "react"
import axios from 'axios';
import { Category } from "../interfaces";

export const categoriesApi = axios.create();

export const useCategories = ( ) => {

    const [isLoadingCategories, setIsLoading] = useState(true);
    const [categories, setCategories] = useState<Category[]>([]);

    const loadCategories = async() => {
        const resp = await categoriesApi.get(`https://rexdiscount.idadns.com:33095/Api/Category/ListCategory`);
        if (resp != undefined) {
            setCategories( resp.data.value );
        } else {
            setCategories([]);
        }
        setCategories( resp.data.value );
        setIsLoading(false);
    }

    useEffect(() => {
        loadCategories();
    }, [])

    return {
        isLoadingCategories,
        categories
    }
}

export const getCategories = async (id: number, page: number) => {
	const resp = await categoriesApi.get(`https://rexdiscount.idadns.com:33095/Api/Item/ListItemCategory?PageNumber=${page}&PageSize=32&idCategory=${id}`);
    if (resp != undefined) {
        return resp.data.value;
    } 
    return [];
}

export const getSubCategories = async (id: number, idSubCategory: number, page: number) => {
	const resp = await categoriesApi.get(`https://rexdiscount.idadns.com:33095/Api/Item/ListItemCategorySubCategory?PageNumber=${page}&PageSize=32&idCategory=${id}&idSubCategory=${idSubCategory}`);
	if (resp != undefined) {
        return resp.data.value;
    } 
    return [];
}