import axios from 'axios';

export const hearAboutsApi = axios.create();

export const getHearAbouts = async () => {
	const resp = await hearAboutsApi.get(`https://rexdiscount.idadns.com:33095/Api/BusinessPartner/GetHearAbouts`);
    if (resp != undefined) {
        return resp.data;
    } 
    return [];
}