import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SocialIcon } from 'react-social-icons';

export const Title = styled('h1')(({ theme }) => ({
  fontSize: '2.5rem',
  textAlign: 'center'
}));

export const CardContact = styled(Card)(({ theme }) => ({
  minWidth: '12rem', 
  minHeight: '12rem',
  margin: '1rem',
  width: '30%',
  boxShadow: "2px 2px 2px #000",
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
  },
  [theme.breakpoints.up('md')]: {
    width: '70% !important',
  }
}));

export const CardContactBig = styled(Card)(({ theme }) => ({
  minWidth: '12rem', 
  minHeight: '12rem',
  margin: '1rem',
  boxShadow: "2px 2px 2px #000"

}));

export const ContainerCards = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}));

export const IconSocial = styled(SocialIcon)(({ theme }) => ({
  margin: '0.5rem',
  borderRadius: 100,
  '&:hover': {
    boxShadow: "2px 2px 2px #000"
},
}));

export const IconWrapper = {
  width: '3.5rem',
  height: '3.5rem',
  color: '#10b981'
};
