import React from 'react'
import { AboutUsContainer, AboutUsImg, AboutUsP, AboutUsRow, AboutUsSecondP, AboutUsTitle, AboutUsTitleContainer, Container2, ImageAbout, SecondH1, TitleH2 } from './style';

const AboutUsComponent: React.FC = () => {

    return (
        <>
            <AboutUsContainer>
                <AboutUsTitleContainer>
                    <AboutUsTitle className={"title2"}>About Us</AboutUsTitle>
                    <AboutUsP>
                        <AboutUsRow>
                            <AboutUsImg>
                                <TitleH2>FROM HUMBLE BEGINNINGS </TitleH2>
                                <ImageAbout src="/rex.webp" alt="this is img" />
                            </AboutUsImg>
                            <AboutUsImg>
                                <TitleH2>PRESENT</TitleH2>
                                <ImageAbout src="/after.png" alt="this is img" />
                            </AboutUsImg>
                        </AboutUsRow>
                    </AboutUsP>
                </AboutUsTitleContainer>
            </AboutUsContainer>
            <Container2>
                <SecondH1>
                    <AboutUsSecondP>
                        Rex Discount is a family owned and operated grocery wholesaler and
                        distributor, with a rich history that dates back to its founding in
                        the early 80's. The company was established by Jose “Pepe”
                        Carrera, a Cuban Inmigrant who came to the United States in search
                        of a better life. Pepe knew that with opportunity, integrity, and
                        hard work that anything was possible. He believed in the American
                        Dream. Starting from humble beginnings, Rex began as a small corner
                        discount market in Hialeah, catering to Miami’s minority community.
                        At that time, many local mom and pop stores in the area faced
                        difficulties in getting the attention of major
                        distributors struggling to source products and unable to stock their
                        shelves. Recognizing this pressing need, Rex stepped in to become a
                        lifeline for the community. <br /> <br />
                        Rex Discount prioritized building strong relationships with major
                        manufacturers, ensuring a reliable supply of quality products. Never
                        losing sight of its roots or the needs of the community, Rex became
                        a vital resource for small businesses in Miami enabling access to a
                        wide range of products, and allowing them to compete effectively in
                        the market. <br /> <br />
                        Over the years Rex has expanded both its operations, as well as our
                        customer base. Through a commitment to excellence, integrity, and
                        personalized service, the company has earned a reputation for being
                        a trusted partner in the grocery industry across the globe. Despite
                        its growth, Rex remains a family-owned business, maintaining our
                        dedication to community support along with customer satisfaction.
                        <br /> <br />
                        Today, Rex Discount stands as a testament to what is possible with
                        hard work and service to the community. We are proud to be your
                        trusted source for ensuring that your communities are receiving the
                        highest quality products at the most competitive prices. From Miami
                        across the globe, and from our family to yours, Rex is only getting
                        started.
                    </AboutUsSecondP>
                </SecondH1>
            </Container2>
        </>
    )

}

export default AboutUsComponent;
