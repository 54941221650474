import { InputBase, styled } from "@mui/material";

export const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#bdbdbd",
    '&:hover': {
      backgroundColor: 'rgba(126, 126, 126, 0.75)',
    },
    marginBottom: '2rem',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
  }));
  
  export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    color: '#000',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#000',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  }));