import { CardContent, Divider } from '@mui/material';
import React from 'react'
import { CardContact, CardContactBig, ContainerCards, IconSocial, IconWrapper, Title } from './style';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';

const Contact: React.FC = () => {

    return (
        <div style={{marginTop: '1rem'}}>
            <Title>Contact Us</Title>

            <Divider variant="inset" sx={{ml: 0, mb: 2}} />

            <ContainerCards>
                <CardContact>
                    <CardContent sx={{fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <LocationOnIcon sx={IconWrapper} />
                        <h2><b>Store Location</b></h2>
                        <Typography component={'span'}>3690 NW 62nd St Miami, FL 33147</Typography>
                    </CardContent>
                </CardContact>
                <CardContact>
                    <CardContent sx={{fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <PhoneIcon sx={IconWrapper} />
                        <h2><b>Contact us by phone</b></h2>
                        <Typography component={'span'}>
                            (305) 633-6650
                        </Typography>
                    </CardContent>
                </CardContact>
                <CardContact>
                    <CardContent sx={{fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <EmailIcon sx={IconWrapper} />
                        <h2><b>Contact us via e-mail</b></h2>
                        <Typography component={'span'}>
                            If you have any questions please contact our Director of Operations at:&nbsp; 
                            <a href="mailto:sales@rexdiscount.com">
                                sales@rexdiscount.com
                            </a>
                        </Typography>
                    </CardContent>
                </CardContact>
            </ContainerCards>
            <CardContactBig>
                <CardContent sx={{fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {/* <Typography component={'span'}>
                        <Typography>If you are an export customer, please contact the head of our export department at:</Typography>

                        John I. Fedak (305) 345-7561

                        <Typography>Skype: Rexdiscount</Typography>
                        <a href="mailto:export@rexdiscount.com">
                            export@rexdiscount.com
                        </a>
                    </Typography> */}
                    <Typography component={'span'} sx={{  display: 'flex', alignItems: 'center', mt: 5}}>
                        Follow Us:
                        <IconSocial url="https://www.facebook.com/RexDiscountInc" />
                        <IconSocial url="https://www.instagram.com/rexwholesale" />
                        <IconSocial network="x" url="https://twitter.com/rexwholesale" />
                    </Typography>
                </CardContent>
            </CardContactBig>
        </div>
    )
}

export default Contact;