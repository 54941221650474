import { styled } from '@mui/material/styles';


export const FavoriteContainer = styled('div')(({ theme }) => ({
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        //marginTop: '1rem',

    },
}));

export const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '1rem 0',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        textAlign: 'center',
        marginBottom: '1rem',
    },
  }));

export const TitleH1 = styled('h1')(({ theme }) => ({
    fontSize: '24px',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
    },
}));

export const TitleH2 = styled('h2')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    fontWeight: '100',
    fontSize: '102px',
    textTransform: 'uppercase',
    WebkitTextStroke: '2px rgb(212,54,49)',
    WebkitTextFillColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
        textAlign: 'center',
        marginBottom: '1rem',
    },
}));

export const Row = styled('div')(({ theme }) => ({
    display: 'grid',
    margin: '2rem 0rem',
    gridTemplateColumns: 'auto auto auto auto',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
        display: 'grid',
        margin: '2rem 0rem',
        gridTemplateColumns: 'auto',
        gap: '1rem',
    },
}));

export const PText = styled('p')(({ theme }) => ({
    color: "#940000",
    fontSize: "14px",
    width: "75%",
    margin: "auto",
    paddingTop: "1rem",
}));
