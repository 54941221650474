import React, { useEffect, useRef } from 'react';
import './SignInModal.css';

interface SignInModalProps {
  closeModal: () => void;
  showModal: boolean;
}

const SignInModal: React.FC<SignInModalProps> = ({ closeModal, showModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal, closeModal]);

  const handleSubmit = () => {
    console.log("Sign In");
    window.location.href = "https://rexdiscount.idadns.com:33095/portalv2";
  };

  return (
    <div className={`modal ${showModal ? 'show' : 'hide'}`}>
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Please click to go to the portal</h2>
        </div>
        <div className="modal-body">
          <button onClick={handleSubmit} className="blue-button">Sign In</button>
        </div>
      </div>
    </div>
  );
}

export default SignInModal;
