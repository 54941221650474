import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ErrorP, TextInput } from './style';
import { AuthModalProps, User } from '../../interfaces';
import { CircularProgress, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { signUp } from '../../hooks/useAuth';
import { getHearAbouts } from '../../hooks/useHearAbouts';
import { Countries } from "./countries";
import { States } from "./states";
import HearAbouts from './hearAbouts.json';
//import ha_Json from `${process.env.PUBLIC_URL}/hearAbouts.json`;
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Input } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './SignInModal.css';
import { styled } from '@mui/material/styles';
import { Padding } from '@mui/icons-material';
// Assuming HearAboutOption has a structure like { name: string }
interface HearAboutOption {
  name: string;
}
// Define a custom style for the menu items
const CustomMenuItem = styled(MenuItem)`
  max-height: 200px; /* Adjust the maximum height as needed */
  overflow-y: auto;  /* Enable vertical scrolling if needed */
`;

const validate = (values: any) => {
    const errors: User = {} as User;
    if (!values.email ) {
        errors.email = "The Email is mandatory"
    }
    if (!values.email.match(/.+@.+/) ) {
        errors.email = "The email doesnt have a correct format"
    }
    if (!values.email.match(/.+@.+/) ) {
        errors.email = "The email doesnt have a correct format"
    }
    if (!(values.password==values.confirmPassword) ) {
        errors.password = "Password confirmation doesnt match"
        errors.confirmPassword = "Password confirmation doesnt match"
    }
    if (!values.password ) {
        errors.password = "The field is mandatory"
    }
    if (!values.confirmPassword ) {
        errors.confirmPassword = "The field is mandatory"
    }
    if (!values.lastName ) {
        errors.lastName = "The field is mandatory"
    }
    if (!values.address ) {
        errors.address = "The field is mandatory"
    }
    if (!values.city ) {
        errors.city = "The field is mandatory"
    }
    if (!values.cellPhone ) {
        errors.cellPhone = "The field is mandatory"
    }
    if (!values.company ) {
        errors.company = "The field is mandatory"
    }
    if (!values.jobDescription ) {
        errors.jobDescription = "The field is mandatory"
    }
    if (!values.country ) {
        errors.country = "The field is mandatory"
    }
 
    if (!values.passport ) {
        errors.passport.passport = "The field is mandatory"
    }
    if (!values.file ) {
        errors.file.file = "The field is mandatory"
    }

    return errors;
}


const AuthModal: React.FC<AuthModalProps> = ({closeModal, showModal}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<User>({} as User);
    const [haJson, setHAJson] = useState([]);
    
    const [user, setUser] = useState(
        {
            email: '', 
            name: '', 
            lastName: '', 
            password: '', 
            confirmPassword: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            whatsapp: '',
            cellPhone: '',
            fax: '',
            company: '',
            jobDescription: '',
            country: '',
            hearAboutUs: '',
            prefix: '',
            code: '', 
            cardCode: '',
            type: 'Export',
            companyId: '',
            companyIdExp: '',
            file: {
                file: '',
                fileName: '',
                fileExtension: ''
            },
            passport: {
                passport: '',
                passportName: '',
                passportExtension: ''
            }
        }
    );

    const prefixes = [
        {
            value: 'Sr.',
            label: 'Sr.',
        },
        {
            value: 'Mr.',
            label: 'Mr.',
        },
        {
            value: 'Dr.',
            label: 'Dr.',
        },
        {
            value: 'Mrs.',
            label: 'Mrs.',
        },
        {
            value: 'Ms.',
            label: 'Ms.',
        },
    ];

    const getBase64 = (file: any) => {
        let data = {
            file: '',
            fileName: '',
            fileExtension: ''
        }
        const sliceValue = file.type.indexOf("/");
        data.fileName = file.name;
        data.fileExtension = file.type.slice(sliceValue+1);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (reader.result) {
                const sliceResult = reader.result?.toString().indexOf(",");
                data.file = reader.result?.toString().slice(sliceResult+1);
      // Check file size (in bytes)
      const fileSizeInBytes = (data.file.length * 3) / 4 - 2;
      const fileSizeInKB = fileSizeInBytes / 1024;

      if (fileSizeInKB <= 1501) {
        setUser({ ...user, file: data });
      } else {
        alert('File size exceeds the limit (1.5 Mb). Please choose a smaller file.');
      }
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const getBase64Passport = (file: any) => {
        let data = {
            passport: '',
            passportName: '',
            passportExtension: ''
        }
        const sliceValue = file.type.indexOf("/");
        data.passportName = file.name;
        data.passportExtension = file.type.slice(sliceValue+1);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (reader.result) {
                const sliceResult = reader.result?.toString().indexOf(",");
                data.passport = reader.result?.toString().slice(sliceResult+1);
      // Check file size (in bytes)
      const fileSizeInBytes = (data.passport.length * 3) / 4 - 2;
      const fileSizeInKB = fileSizeInBytes / 1024;

      if (fileSizeInKB <= 1501) {
        setUser({ ...user, passport: data });
      } else {
        alert('File size exceeds the limit (1.5 MB). Please choose a smaller file.');
      }
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const convertFile = (e: any) => {
        getBase64(e.target.files[0]);
    }

    const convertPassport = (e: any) => {
        getBase64Passport(e.target.files[0]);
    }

	const handleSubmit = async () => {
        setErrors(user);
        const result = validate(user);
        setErrors(result);

        if (user.type === 'Domestic') {
            if (!user.file?.file) {
                alert('Please upload a Tax Certificate.');
                return;
            }
        } else {
            if (!user.file?.file || !user.passport?.passport) {
                alert('Please upload both a Business License and a Passport Photo.');
                return;
            }
        }
        if (!Object.keys(result).length) {
            setIsLoading(true);
            await signUp(user).then(res => {
            //(res);
                
                 //alert(res);
               if (res === "200") {
                closeModal();
               alert("Thank you for signing up with Rex Discount Wholesale Grocers. Your request has been sent to administration to help assign you to your specialized sales rep for your business type and location. Please allow us a little time to get you approved and you will be receiving an email with more information to get started. Welcome to the Rex family, we've been expecting you!");
                setIsLoading(false);
                }else{
                   
                    alert("An error was Generated, Please try again in a few minutes, if error persist contact us. Error: "+res);
                    setIsLoading(false);
                    /*const jsonObject = JSON.parse(res);
                    alert("An error was Generated, Please try again in a few minutes, if error persist contact us. Error: adonde vas"+jsonObject.error.message);*/
                }
            }).catch(err => {
                //alert("Registro fallido!");                
                //alert(err.message);
                //setIsLoading(false);
                handleSubmitInCatch();
            })
        }
    }

    const handleSubmitInCatch = async () => {
        setErrors(user);
        const result = validate(user);
        setErrors(result);

        if (user.type === 'Domestic') {
            if (!user.file?.file) {
                alert('Please upload a Tax Certificate.');
                return;
            }
        } else {
            if (!user.file?.file || !user.passport?.passport) {
                alert('Please upload both a Business License and a Passport Photo.');
                return;
            }
        }
        if (!Object.keys(result).length) {
            setIsLoading(true);
            await signUp(user).then(res => {
            //(res);
                
                 //alert(res);
               if (res === "200") {
                closeModal();
               alert("Thank you for signing up with Rex Discount Wholesale Grocers. Your request has been sent to administration to help assign you to your specialized sales rep for your business type and location. Please allow us a little time to get you approved and you will be receiving an email with more information to get started. Welcome to the Rex family, we've been expecting you!");
                setIsLoading(false);
                }else{
                   
                    alert("An error was Generated, Please try again in a few minutes, if error persist contact us. Error: "+res);
                    setIsLoading(false);
                    /*const jsonObject = JSON.parse(res);
                    alert("An error was Generated, Please try again in a few minutes, if error persist contact us. Error: adonde vas"+jsonObject.error.message);*/
                }
            }).catch(err => {
            })
        }
    }

    const getHearAbouts_ = async () => {
            
        const resp = await getHearAbouts();
        console.log(resp)
        setHAJson(resp);
    }
    
    if (showModal){        
        if (haJson.length == 0) {
            getHearAbouts_();      
        }             
    }

    return (        

        <div>
            <Dialog
                open={showModal}
                onClose={closeModal}
                scroll={'body'}
                maxWidth={'md'}
                style={{ zIndex: '1000', padding: '20px', margin: '10px' }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {/* <div style={{ padding: '0px' }}> */}
                <div style={{display: 'flex', flexDirection: 'column', padding: '10px'}}>
                    <form onSubmit={handleSubmit}>
                        <Typography id="transition-modal-title" variant="h5" component="h2" sx={{marginBottom: '10px'}}>
                            Sign Up
                        </Typography>
                        <Typography fontSize={'1rem'}>
                            Credentials Info:
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item={true} xs={12} sm={12} md={3}>
                                <TextInput                                 
                                    required
                                    id="email"
                                    label="Email"
                                    type={'email'}
                                    sx={{width: '100%'}}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    onChange={(text) => setUser({...user, email: text.target.value})}
                                />
                                {errors!.email && <ErrorP>{errors!.email}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="password"
                                    label="Password"
                                    type={'password'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 20}}  // Limiting to 20 characters
                                    onChange={(text) => setUser({...user, password: text.target.value})}
                                />
                                {errors!.password && <ErrorP>{errors!.password}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    type={'password'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 20 }}  // Limiting to 20 characters
                                    onChange={(text) => setUser({...user, confirmPassword: text.target.value})}
                                />
                                {errors!.confirmPassword && <ErrorP>{errors!.confirmPassword}</ErrorP>}
                            </Grid>
                        </Grid>
                        <br></br>
                        <Typography fontSize={'1rem'}>
                            Account Info:
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="company"
                                    label="Company Name."
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 20 }} 
                                    onChange={(text) => setUser({...user, company: text.target.value})}
                                />
                                {errors!.company && <ErrorP>{errors!.company}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    id="prefix"
                                    select
                                    label="Select prefix"
                                    sx={{width: '100%', zIndex: "2002"}}
                                    value={user.prefix}
                                    
                                    onChange={(text) => setUser({...user, prefix: text.target.value})}
                                >
                                    {prefixes.map((option, index) => (
                                        <MenuItem style={{zIndex: 2002 + index}} key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextInput>
                                {errors!.prefix && <ErrorP>{errors!.prefix}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="name"
                                    label="First Name"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 30 }} 
                                    onChange={(text) => setUser({...user, name: text.target.value})}
                                />
                                {errors!.name && <ErrorP>{errors!.name}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="lastName"
                                    label="Last Name"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 10 }} 
                                    onChange={(text) => setUser({...user, lastName: text.target.value})}
                                />
                                {errors!.lastName && <ErrorP>{errors!.lastName}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="address"
                                    label="Company Address"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 100 }} 
                                    onChange={(text) => setUser({...user, address: text.target.value})}
                                />
                                {errors!.address && <ErrorP>{errors!.address}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    required
                                    id="city"
                                    label="City"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 20 }} 
                                    onChange={(text) => setUser({...user, city: text.target.value})}
                                />
                                {errors!.city && <ErrorP>{errors!.city}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                {/* <TextInput
                                    required
                                    id="state"
                                    label="State / Providence"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 20 }} 
                                    onChange={(text) => setUser({...user, state: text.target.value})}
                                /> */}
                                <TextInput
                                    id="state"
                                    select
                                    value={user.state}
                                    label="Select state *"
                                    sx={{ width: '100%' }}                                    
                                    onChange={(text) => setUser({...user, state: text.target.value})}
                                    //helperText="Please select your state"
                                    
                                >
                                    {States.map((option) => (
                                        <MenuItem style={{zIndex: 6000}} sx={{zIndex: 6000}} key={option.code} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextInput>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    id="zipCode"
                                    label="Zip Code"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    inputProps={{ maxLength: 10 }} 
                                    onChange={(text) => setUser({...user, zipCode: text.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    id="country"
                                    select
                                    value={user.country}
                                    label="Select country *"
                                    sx={{width: '100%', zIndex: '6000'}}
                                    onChange={(text) => setUser({...user, country: text.target.value})}
                                    helperText="Please select your country"
                                    
                                >
                                    {Countries.map((option) => (
                                        <MenuItem style={{zIndex: 6000}} sx={{zIndex: 6000}} key={option.code} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextInput>
                                {errors!.country && <ErrorP>{errors!.country}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <PhoneInput
                                    inputProps={{
                                        name: 'whatsapp',
                                        required: true,
                                        //autoFocus: true,
                                        
                                        }}
                                        containerStyle={{marginTop: '1rem'}}
                                        inputStyle={{width: '100%', height: '55px'}}
                                        placeholder="WhatsApp"
                                        onChange={(text) => setUser({...user, whatsapp: text!})}
                                    //id="whatsapp"
                                    //label="WhatsApp"
                                  //  type={'text'}
                                   // sx={{width: '100%'}}
                                   // onChange={(text) => setUser({...user, whatsapp: text.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <PhoneInput                            
                                    inputProps={{
                                        name: 'cellPhone',
                                        required: true,
                                        //autoFocus: true,
                                        
                                      }}
                                      
                                    containerStyle={{marginTop: '1rem'}}
                                    
                                    inputStyle={{width: '100%', height: '55px'}}
                                    placeholder="Cell Phone *"
                                    
                                    onChange={(text) => setUser({...user, cellPhone: text!})}/>
                                {errors!.cellPhone && <ErrorP>{errors!.cellPhone}</ErrorP>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <PhoneInput
                                       inputProps={{
                                        name: 'fax',
                                        required: true,
                                        //autoFocus: true,
                                        
                                      }}
                                      containerStyle={{marginTop: '1rem'}}
                                      inputStyle={{width: '100%', height: '55px'}}
                                      placeholder="Fax"
                                      onChange={(text) => setUser({...user, fax: text!})}
                                   /* id="fax"
                                    label="Fax"
                                    type={'text'}
                                    sx={{width: '100%'}}
                                    onChange={(text) => setUser({...user, fax: text.target.value})}*/
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                            <TextInput
          id="hearAboutUs"
          select
          value={user.hearAboutUs}
          label="Where did you hear about us?"
          sx={{ width: '100%', marginTop: '1rem' }}
          inputProps={{ maxLength: 250 }}
          onChange={(text) => setUser({ ...user, hearAboutUs: text.target.value })}
          helperText="Please select your ways to Hear About Us"
        >
                    {haJson.map((option: HearAboutOption ) => (
                        <MenuItem
                        style={{ zIndex: 2500 }}
                        sx={{ zIndex: 2502 }}
                        key={option.name}
                        value={option.name}
                        >
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextInput>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Export"
                                    name="radio-buttons-group"
                                    id="type"
                                    onChange={(text) => setUser({...user, type: text.target.value})}
                                >
                                    <FormControlLabel value="Domestic" control={<Radio />} label="Domestic" />
                                    <FormControlLabel value="Export" control={<Radio />} label="Export" />
                                </RadioGroup>
                            </Grid>

                            { ( user.type === 'Domestic' ) ? 
                                <Grid item xs={12} sm={6}>
                                    <InputLabel htmlFor="resale">Tax Certificate *</InputLabel>
                                    <Input required type='file' inputProps={{ accept: "image/*,.pdf, image/jpg" }} id='file' onChange={( event )=> convertFile(event)}/>
                                        
                                </Grid>
                                :
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel htmlFor="licence">Business License *</InputLabel>
                                        <Input required type='file' inputProps={{ accept: "image/*,.pdf, image/jpg" }} onChange={( event )=> convertFile(event)}/>
                                        {errors!.file && <ErrorP>{errors!.file.file}</ErrorP>}
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel htmlFor="passport ">Passport Photo *</InputLabel>
                                        <Input required type='file' inputProps={{ accept: "image/*,.pdf, image/jpg" }} onChange={( event )=> convertPassport(event)}/>
                                        {errors!.passport && <ErrorP>{errors!.passport.passport}</ErrorP>}
                                    </Grid>
                                </Grid>
                            }

<Grid container direction="row" alignItems="center">
  <Grid item xs={12} style={{ width: '100%', marginBottom: '1rem', padding: '5px 5px' }}>
    <TextInput
      required
      id="jobDescription"
      label="Job Description (Max 200 Char)"
      multiline
      rows={3}
      style={{ width: '100%' }}
      type={'text'}
      inputProps={{ maxLength: 249 }} 
      onChange={(text) => setUser({ ...user, jobDescription: text.target.value })}
    />
    {errors!.jobDescription && <ErrorP>{errors!.jobDescription}</ErrorP>}
  </Grid>
  <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
    {/* <Button hidden
      variant="contained"
      onClick={() => handleSubmit()}
      style={{ width: 'fit-content', padding: '10px 20px', fontSize: '0.9rem' }}
    >
      Sign
    </Button> */}

  </Grid>
</Grid>

           {/* Sign Button */}

                        </Grid>
                        <br></br>
                    </form>
                    <Button 
                    variant="contained" 
                    onClick={() => handleSubmit()}
                    sx={{alignSelf: 'center', width: 'fit-content'}}
                >
                    Sign Up
                </Button>
                </div>
               {/* </div>   */}

                
                {(isLoading) ?
                    <div style={{display: 'flex', justifyContent: 'center', position: 'absolute', zIndex: '100',  left: '0', top: '0', right: '0', bottom: '0', alignItems: 'center'}}>
                        <CircularProgress size={100} color="success" />
                    </div> : null
                }
					
            </Dialog>
        </div>
    )
}

export default AuthModal;