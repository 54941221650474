import { useEffect, useState } from "react"
import axios from 'axios';
import { Product } from "../interfaces";

export const productsApi = axios.create();

export const useProducts = (page: number) => {

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>([]);
    const [quantity, setQuantity] = useState(0);

    const loadProducts = async(page: number) => {
        const resp = await productsApi.get(`https://rexdiscount.idadns.com:33095/Api/Item/ListItem?PageNumber=${page}&PageSize=32`);
		
		//const resp = await productsApi.get(`https://rexdiscount.com/api/products?categoryid=&customerid=&rootcateg=true&onlyactive=true&pagenumber=${page}&pagesize=`);
		if (resp != undefined) {
            setProducts( resp.data.value.data );
            setQuantity( resp.data.value.totalRecords);
        } else {
            setProducts([]);
            setQuantity(0);
        }
        setIsLoading(false);
    }

    const searching = async(page: number, search: string) => {
        const resp = await productsApi.get(`
        https://rexdiscount.idadns.com:33095/Api/Item/ListItemSearch?PageNumber=${page}&PageSize=32&search=${search}
        `);
		//https://rexdiscount.com/api/products/search?criteria=${search}&customerid=&onlyactive=true&pagenumber=${page}&pagesize=
        if (resp != undefined) {
            setProducts( resp.data.value.data );
            
        } else {
            setProducts( [] );
        }
        setQuantity( resp.data.value.totalRecords)
        setIsLoading(false);
    }

    useEffect(() => {
        loadProducts(page);
    }, [])

    return {
        isLoading,
        products,
        quantity,
        loadProducts,
        searching
    }
}