import React from 'react'
import { FavoriteContainer, Row, TitleH1, TitleH2, TitleRow } from './style';

const FavoriteComponent: React.FC = () => {
    /* const data = [
        {
            id: 1,
            description: " ",
            image:
                "https://thumbs.dreamstime.com/b/tin-heinz-baked-beans-cut-out-169178564.jpg",
        },
        {
            id: 2,
            description: " ",
            image:
                "https://aldprdproductimages.azureedge.net/media/resized/$Aldi_GB/03.03.23/4088600072494_0_XL.jpg",
        },
        {
            id: 3,
            description: " ",
            image:
                "https://media.naheed.pk/catalog/product/cache/49dcd5d85f0fa4d590e132d0368d8132/1/1/1159209-1.jpg",
        },
        {
            id: 4,
            description: " ",
            image:
                "https://media.naheed.pk/catalog/product/cache/49dcd5d85f0fa4d590e132d0368d8132/1/0/1075146-1.jpg",
        },
    ]; */
    const data = [
        {
          id: 1,
          description: " ",
          image:
            "/MainImages/principal1.jpg",
        },
        {
          id: 2,
          description: " ",
          image:
          "/MainImages/principal2.jpg",
        },
        {
          id: 3,
          description: " ",
          image:
          "/MainImages/principal3.jpg",
        },
        {
          id: 4,
          description: " ",
          image:
          "/MainImages/principal4.jpg",
        },
      ];
    return (
        <FavoriteContainer>
            <TitleRow>
                <TitleH1 style={{ textAlign: "center" }}>
                    Your favorite B2B wholesale grocer boasting an extensive inventory of
                    over 11,000 skus, featuring a wide range of nationally recognized
                    products, alongside premium private label brands. Our carefully
                    curated inventory caters to our diverse customer base, making it a
                    one-stop shop for all your wholesale grocery needs.
                </TitleH1>
                <TitleH2 className={"title2"}>KEY REX ITEMS</TitleH2>
            </TitleRow>

            <Row>
                {data.map((val) => (
                    <div key={val.id}>
                        <div className={"card__image-container"} style={{background: "white"}}>
                        <img
                            style={{height: "100%", objectFit: "contain"}}
                            className="card-image"
                            src={val.image}
                            alt="Detailed image description would go here."
                        />
                        </div>
                        <p style={{
                            color: "#940000",
                            fontSize: "14px",
                            width: "75%",
                            margin: "auto",
                            paddingTop: "1rem"}}
                        >
                            
                        </p>
                    </div>
                ))}
            </Row>

        </FavoriteContainer>
    )
}

export default FavoriteComponent;