import { styled } from '@mui/material/styles';


export const AboutUsContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#7297a0',
    marginTop: '45rem',
    padding: '4rem 0',
    [theme.breakpoints.down('sm')]: {
        marginTop: '8rem',
    },
}));

export const AboutUsTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    lineHeight: '20px',
    fontWeight: 'bolder',
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginTop: '4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        lineHeight: '20px',
        fontWeight: 'bolder',
    },
}));

export const AboutUsTitle = styled('h1')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    fontweight: '100',
    WebkitTextStroke: '2px rgb(212,54,49)',
    fontSize: '102px',
    textTransform: 'uppercase',
    WebkitTextFillColor: 'transparent',
    marginBottom:' 2rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
        marginBottom: '0rem',
    },
}));

export const AboutUsP = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '4rem',
    marginBottom: '-17rem',
    paddingBottom: '4rem',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        gap: '2rem',
        paddingBottom: '0rem',
    },
}));

export const AboutUsRow = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const ImageAbout = styled('img')(({ theme }) => ({
    width: '90%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '1rem',
    },
}));

export const AboutUsImg = styled('div')(({ theme }) => ({
    display: 'flex',
    alignitems: 'flex-start',
    justifyContent: 'flex-end',
    gap: '1rem',
    color: '#940000',
    flexDirection: 'column-reverse',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        flexDirection: 'column',
    },
}));

export const Container2 = styled('div')(({ theme }) => ({
    width: '90%',
    margin: 'auto',
}));

export const TitleH2 = styled('h2')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
    },
}));

export const SecondH1 = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '15rem',
    marginBottom: '5%',
    gap: '2rem',
    lineHeight: '20px',
    fontWeight: 'bolder',
}));

export const AboutUsSecondP = styled('p')(({ theme }) => ({
    fontWeight: '500',
    color: '#940000',
    fontSize: '1.5rem',
    lineHeight: '1.25',
}));