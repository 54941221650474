import React from 'react';
import logo from '../../assets/Images/logo.svg';
import { Image, NavBar, ContainerNav, SubContainer, TextP } from './style';
import AuthModal from '../AuthModal';
import SignInModal from '../AuthModal/indexSignIn';
import { NavBarProps } from '../../interfaces';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from './appbar';

const NavbarComponent: React.FC<NavBarProps> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalSignIn, setOpenModalSignIn] = React.useState(false);
  const navigate = useNavigate();

   /*const pages = [
    { title: 'Home', path: '/' },
    { title: 'Products', path: '/products' },
    { title: 'Contact Us', path: '/contact' },
  ]; */
  const pages = [
    { title: 'Home', path: '/PortalRexDiscount/' },
    { title: 'Products', path: '/PortalRexDiscount/products' },
    { title: 'Contact Us', path: '/PortalRexDiscount/contact' },
  ];

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  const handleClickSignInModal = () => {
    window.location.href = "https://rexdiscount.idadns.com:33095/portalv2";
    //setOpenModalSignIn(!openModalSignIn);
  };

  const handleOpenNavMenu = () => {
    navigate('/');
  };
return <ResponsiveAppBar/>
  return (
    <NavBar>
      <ContainerNav>
        <SubContainer>
        <TextP href={pages[0].path}>Home</TextP>
          <TextP href={pages[1].path}>Products</TextP>
          <TextP href={pages[2].path}>Contact Us</TextP>
        </SubContainer>
        <Image src={logo} onClick={handleOpenNavMenu} style={{ cursor: 'pointer' }} />
        <SubContainer>
          <TextP onClick={handleClick}>Sign up</TextP>
          <TextP onClick={handleClickSignInModal}>Sign in</TextP>
        </SubContainer>
      </ContainerNav>
      <AuthModal showModal={openModal} closeModal={() => setOpenModal(false)} />
      <SignInModal showModal={openModalSignIn} closeModal={() => setOpenModalSignIn(false)} />
    </NavBar>
  );
};

export default NavbarComponent;
