import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import NavbarComponent from './components/Navbar';
import Auth from './screens/Auth';
import Contact from './screens/Contact';
import Home from './screens/Home';
import Products from './screens/Products';

function App() {
  return (
    <BrowserRouter basename="/PortalRexDiscount">
      <NavbarComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/auth" element={<Auth />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
