import { ContainerFooter, FooterApp, HFooter } from './style';
import { BsFacebook, BsInstagram, BsX } from 'react-icons/bs';
import ximage from '../../assets/Images/X.svg';

const Footer = () => {
    return (
        <FooterApp>
            <ContainerFooter>
            <HFooter>FROM OUR FAMILY TO YOURS</HFooter>
            <div style={{display:"flex" , alignItems:"center", gap:"1rem"}}>
   
                <a href="https://www.instagram.com/rexwholesale" target="_blank" rel="noopener noreferrer" style={{cursor:"pointer"}}>
                <BsInstagram style={{cursor:"pointer"}} />
</a>
<a href="https://web.facebook.com/RexDiscountInc?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer" style={{cursor:"pointer"}}>
                <BsFacebook style={{cursor:"pointer"}} />
                </a>   
                <a href="https://twitter.com/rexwholesale" target="_blank" rel="noopener noreferrer" style={{cursor:"pointer"}}>                
                <BsX style={{cursor:"pointer"}} />
                </a>        
            </div>
            
            </ContainerFooter>
        </FooterApp>
    )
}

export default Footer;