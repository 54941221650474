import { styled } from '@mui/material/styles';
import {IoArrowRedo} from 'react-icons/io5'

  export const ExistingContainer = styled('div')(({ theme }) => ({
    position: 'relative',
  }));

  export const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  export const TitleRowMob = styled('div')(({ theme }) => ({
    display: 'none',
  }));

  export const Row = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '350%',
    left: '10%',
  }));

  export const Item = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    width: '100%',
  }));

  export const Hero = styled('div')(({ theme }) => ({
    display: 'none',
  }));

  export const RightIcons = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '50%',
    right:' 15%',
    top: '100%',
    height: '500px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: '300px',
      width: '100%',
      right: '0',
      margin: '10px',
    },
  }));

  export const RightIconsImage = styled('img')(({ theme }) => ({
    width: '162px',
    height: '162px',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
      
    },
  }));

  export const Img1 = styled('div')(({ theme }) => ({
    position: 'absolute',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: '700',
    top: '10%',
    left: '35%',
    [theme.breakpoints.down('sm')]: {
      left: '0',
      fontSize: '14px',
    },
  }));

  export const Img2 = styled('div')(({ theme }) => ({
    position: 'absolute',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: '700',
    top: '50%',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      fontSize: '14px',
    },
  }));

  export const Img3 = styled('div')(({ theme }) => ({
    position: 'absolute',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: '700',
    top: '50%',
    right: '10%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'end',
      fontSize: '14px',
    },
  }));

  export const Img4 = styled('div')(({ theme }) => ({
    position: 'absolute',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: '700',
    top: '90%',
    left: '35%',
    [theme.breakpoints.down('sm')]: {
      left: '0',
      fontSize: '14px',
    },
  }));


  export const Icon1 = styled(IoArrowRedo)(({ theme }) => ({
    position: 'absolute',
    fill: '#940000',
    fontWeight: 'bolder',
    fontSize: '72px',
    top: '100%',
    transform: 'rotate(180deg)',
    left: '71%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
    },
  }));

  export const Icon2 = styled(IoArrowRedo)(({ theme }) => ({
    position: 'absolute',
    fill: '#940000',
    fontWeight: 'bolder',
    fontSize: '72px',
    top: '20%',
    transform: 'rotate(90deg)',
    left: '73%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
    },
  }));

  export const Icon3 = styled(IoArrowRedo)(({ theme }) => ({
    position: 'absolute',
    fill: '#940000',
    fontWeight: 'bolder',
    fontSize: '72px',
    top: '20%',
    left: '13%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
    },
  }));

  export const Icon4 = styled(IoArrowRedo)(({ theme }) => ({
    position: 'absolute',
    fill: '#940000',
    fontWeight: 'bolder',
    fontSize: '72px',
    top: '100%',
    transform: 'rotate(-90deg)',
    left: '11%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
    },
  }));


