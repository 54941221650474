import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const style = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 10,
    boxShadow: 24,
    p: '40px',
    zIndex: '2001'
  };

export const TextInput = styled(TextField)(({ theme }) => ({
    marginTop: '1rem',
}));

export const ErrorP = styled('p')(({ theme }) => ({
    color: 'red',
    paddingTop: '4px',
    fontWeight: 'lighter',
    fontSize: '0.8rem'
}));

export const SignInForm = styled('span')(({ theme }) => ({
    display: 'grid'
}));

export const SignUpForm = styled('span')(({ theme }) => ({
    display: 'grid'
}));

export const ForgotLink = styled(Link)(({ theme }) => ({
    fontSize: '0.8rem',
    display: 'flex',
    justifyContent: 'end',
}));

export const SignTextMode = styled('span')(({ theme }) => ({
    textAlign: 'center',
    fontSize: '0.8rem',
    color: '#4b4b4b',
    cursor: 'pointer',
    marginTop: '20px'
}));