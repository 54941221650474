import React from 'react'
import { SearchIconWrapper, SearchBar, StyledInputBase } from './style'
import SearchIcon from '@mui/icons-material/Search';
import { SearchProps } from '../../interfaces';

const Search: React.FC<SearchProps> = ({ search, handleChange }) => {

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <SearchBar>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={ search } onChange={ handleChange }
                />
            </SearchBar>
    </div>
  )
}

export default Search