import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProductComponent from '../../components/Product';
import Search from '../../components/Search';
import { useProducts } from '../../hooks/useProducts';
import { Category, Product } from '../../interfaces';
import { getCategories, useCategories, getSubCategories } from '../../hooks/useCategories';
import CategoryItem from '../../components/CategoryItem';
import { BodyCategory } from './style';
import ReactPaginate from 'react-paginate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Products: React.FC = () => {

    const [page, setPage] = useState(1);
    const [activePage, setActivePage] = useState(0);
    const [search, setSearch] = useState('');
    const { products, quantity, isLoading, loadProducts, searching } = useProducts(page);
    const { categories } = useCategories();
    const [productsUse, setProductsUse] = useState<Product[]>([]);
    const [quantityUse, setQuantityUse] = useState(0);
    const [categoriesUse, setCategoriesUse] = useState<Category[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isCategory, setIsCategory] = useState(false);
    const [idCategory, setIdCategory] = useState(0);
    const [isSubCategory, setIsSubCategory] = useState(false);
    const [subCategoryInfo, setSubCategoryInfo] = useState({idCategory: 0, idSubcategory: 0});
    const [isPageSelected, setIsPageSelected] = useState(false);

    useEffect(() => {        
        if (isSearching) {
            searching(page, search);
        }else if (isCategory) {
            searchProducts(idCategory);
        }else if (isSubCategory) {            
            const item = {
                parentId: subCategoryInfo.idCategory, 
                id: subCategoryInfo.idSubcategory
            }
            getSubCategoriesProducts(item, idCategory);            
        }
        else {
            loadProducts(page);
        }
    }, [page])

    useEffect(() => {
        setProductsUse(products);
        setQuantityUse(quantity);
    }, [products])
    
    useEffect(() => {
        setCategoriesUse(categories);
        addImages(categories);
    }, [categories])

    useEffect(() => {
        if(search === ''){
            setIsSearching(false);
            setTimeout(async () => {
                await loadProducts(1);
            }, 1000)
            setProductsUse(products);
            setQuantityUse(quantity);
        }else{
            setTimeout(() => {
                searching(page, search);
            }, 1000)
        }
    }, [search])

    const handlePageClick = (event: any) => {
        console.log("test")
        setIsSubCategory(true);
        //setIsCategory(false); //120423
        setIsPageSelected(true);
        setPage(event.selected+1);
        setActivePage(event.selected);       
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setIsCategory(false);
        setIsSearching(true);        
    };

    const addImages = (categories: Category[]) => {
        let number = 1;
        const result: Category[]  = categories.map((item: Category) => {
            item.image = `${number}.jpg`;
            number = number + 1;
            return item;
        });
        setCategoriesUse(result);
    }

    const loadAll = () => {
        setIsSubCategory(false);
        setTimeout(async () => {
            setPage(1);
            setActivePage(0);
            await loadProducts(1);
        }, 1000)
        setProductsUse(products);
        setQuantityUse(quantity);
    }

    const searchProducts = async (id: number) => {
        if (!isCategory || id !== idCategory) {
            setPage(1);
            setActivePage(0);
        }
        setSearch('');
        setIdCategory(id);
        setIsSearching(false);
        setIsCategory(true);
        setIsSubCategory(false); //120423
        const resp = await getCategories(id, page);
		console.log(117, resp)
        setProductsUse(resp.data);
        setQuantityUse(resp.totalRecords);
    }
	
	const getSubCategoriesProducts = async (item: any, id: any) => {
        setIsSubCategory(true);
        setIsCategory(false);
        setIsPageSelected(false);
        setIdCategory(id);
        if (!isPageSelected || subCategoryInfo.idSubcategory !== item.id) {            
            setPage(1);
            setActivePage(0);
        }
        // setPage(1); //NEW
        // setActivePage(0); //NEW
        setSubCategoryInfo({idCategory: item.parentId, idSubcategory: item.id});
		const resp = await getSubCategories(item.parentId, item.id, page);
        if (resp) {
            setProductsUse(resp.data);
            setQuantityUse(resp.totalRecords);
            console.log(134, resp.totalRecords);
        }
	}
    
    return (
        <div style={{marginTop: '1rem'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Search search={ search } handleChange={ handleChange }/>
                <button style={{width: '25%', display: 'flex', justifyContent: 'center', marginBottom: 20, alignSelf: 'center'}} onClick={loadAll}>All Products</button>
            </div>
            <BodyCategory>
                {(categoriesUse) ? 
                    categoriesUse.map((item: Category) => <CategoryItem key={item.id} category={item} search={searchProducts}></CategoryItem>) :
                    <p>No Categories.</p>
                }
            </BodyCategory>

            <Grid container sx={{padding: { xs: '0.5rem', md: '2rem'} }} spacing= {{xs: 0, md: 2}} >
                <Grid item xs= {6} md= {3}>
                    { (categories ) ?
                        categories.map((item: Category) => 
                        <Accordion key={item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Typography sx={{fontSize: { xs: '10px', md: '16px'} }}>{item.name.toUpperCase()}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    { item.subCategories.map( (i: any ) =>
                                        <ListItem key={item.id+Math.random()} disablePadding>
                                            <ListItemButton onClick={()=>{getSubCategoriesProducts(i, item.id)}}>
                                                <ListItemText primary={<Typography sx={{fontSize: { xs: '10px', md: '16px'} }}>{i.name.toUpperCase()}</Typography>} />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>)
                        : 
                        <p>No Categories.</p>
                    }
                    {/* {categories.map((item: Category) => 
                        <Accordion key={item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Typography>{item.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
								<List>
									{ item.subCategories.map( (i: any ) =>
										<ListItem key={item.id+Math.random()} disablePadding>
											<ListItemButton onClick={()=>{getSubCategoriesProducts(i)}}>
												<ListItemText primary={i.name} />
											</ListItemButton>
										</ListItem>
									)}
								</List>
                            </AccordionDetails>
                        </Accordion>
                    
                    )} */}
                    


                </Grid>
                <Grid item xs= {6} md= {9}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {(productsUse) ? 
                        productsUse.map((item: Product) => <ProductComponent key={item.id} product={item}></ProductComponent>) :
                        <p>Couldn't find a product to match the search criteria</p>
                        }
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {(isLoading) ? 
                            <CircularProgress size={100} color="success" />
                            : null 
                        }
                    </div>
                    
                </Grid>

                
            </Grid>
            <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={Math.ceil(quantityUse/32)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={(event) => handlePageClick(event)}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={activePage}
                    />
        </div>
    )
}

export default Products;