import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

  export const NavBar = styled('nav')(({ theme }) => ({
    backgroundColor: 'rgb(212, 54, 49)',
    color: 'white',
    boxShadow: '1px 1px 20px #00000048',
    position: 'sticky',
    top: '0',
    zIndex: '1000',
  }));

  export const ContainerNav = styled('div')(({ theme }) => ({
    display: 'flex !important',
    alignItems: 'center',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      
    },
  }));

  export const SubContainer = styled('div')(({ theme }) => ({
    display: 'flex !important',
    gap: '4rem',
    [theme.breakpoints.down('sm')]: {
      gap: '0rem',
      fontSize: '14px',
    },
  }));

  export const Image = styled('img')(({ theme }) => ({
    width: '162px',
    objectFit: 'contain',
    marginLeft: '-3rem',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: 'auto',
      //objectFit: 'contain',
      marginLeft: '-1rem',
    },
  }));

  export const TextP = styled(Button)(({ theme }) => ({
    fontWeight: '600',
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontFamily: 'rex-logo',
    lineHeight: '0.75',
    textTransform: 'none',
    padding: '2rem 0',
    color: 'white',
    borderBottom: '3px solid transparent',
    '&:hover': {
      border: 'none',
      borderRadius: '0',
      borderBottom: '3px solid #FFCA2E',
      transition: 'ease-in',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: '500',
      cursor: 'pointer',
      fontSize: '12px',
    },
  }));



