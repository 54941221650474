import React from 'react'
import { ItemImage, RightArrowDiv, RightArrowImage, Title } from '../MyAccount/style';
import { ExistingContainer, Hero, Icon1, Icon2, Icon3, Icon4, Img1, Img2, Img3, Img4, Item, RightIcons, RightIconsImage, Row, TitleRow, TitleRowMob } from './style';


const ExistingComponent: React.FC = () => {

    return (
        <ExistingContainer>
            <TitleRow>
                <Title className={"title2"}>
                    Existing Accounts
                </Title>
            </TitleRow>
            <TitleRowMob>
                <Title>
                    Existing <br /> Accounts
                </Title>
            </TitleRowMob>
            <Row sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Item>
                    <div>
                        <ItemImage
                            style={{ borderRadius: "50%" }}
                            src={"/login.png"}
                            alt="Detailed description would go here."
                        />

                        <p style={{ textAlign: "center", fontWeight: "bold" }}>
                            {" "}
                            Click Top Right <br /> SIGN IN
                        </p>
                    </div>
                    <RightArrowDiv>
                        <RightArrowImage />
                    </RightArrowDiv>
                </Item>
            </Row>

            <RightIcons>
                <Img1>
                    <RightIconsImage
                        src={"https://cdn-icons-png.flaticon.com/512/712/712792.png?w=740&t=st=1684789530~exp=1684790130~hmac=bfba2c240cc0a2a460e034f7dccd4c726d5ca1bf58209fd1f4f16ec2d4efa7b2"}
                        alt="Detailed description would go here."
                    />
                    <p>2-3 Day <br /> Order Fulfillment</p>
                </Img1>
                <Img2>
                    <RightIconsImage
                        src={"https://cdn-icons-png.flaticon.com/512/1187/1187005.png"}
                        alt="Detailed description would go here."
                    />
                    <p>Add Products to Digital Cart</p>
                </Img2>
                <Img3>
                    <RightIconsImage
                        src={"https://apps.odoo.com/web/image/loempia.module/61287/icon_image?unique=1d68fc9"}
                        alt="Detailed description would go here."
                    />
                    <p>Finalize Invoice Payment</p>
                </Img3>
                <Img4>
                    <RightIconsImage
                        src={"/5.png"}
                        alt="Detailed description would go here."
                    />
                    <p>Available
                    for Pick-up <br /> and Local Delivery</p>
                </Img4>
                <Icon1 style={{fill:"#940000"}} />
                <Icon2 style={{fill:"#940000"}} />
                <Icon3 style={{fill:"#940000"}} />
                <Icon4 style={{fill:"#940000"}} />
            </RightIcons>
            <Hero/>
        </ExistingContainer>
    )

}

export default ExistingComponent;
