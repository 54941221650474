import { styled } from '@mui/material/styles';
import RightArrow from "../../svg/RightArrow";

  export const MyAccountContainer = styled('div')(({ theme }) => ({
    marginBottom: '4rem',
    backgroundColor: '#7297a0',
    padding: '4rem',
    color: '#940000',
  }));

  export const TittleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
    flexDirection: 'column',
  }));

  export const Title = styled('h1')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    fontWeight: '100',
    fontSize: '102px',
    textTransform: 'uppercase',
    WebkitTextStroke: '2px rgb(212,54,49)',
    WebkitTextFillColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      fontSize: '42px',
      textAlign: 'center',
    },
  }));

  export const Row = styled('div')(({ theme }) => ({
    display: 'grid',
    margin: '2rem 0',
    gridTemplateColumns: 'auto auto auto auto auto',
    gap: '1rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      margin: '2rem 0',
      gridTemplateColumns: 'auto',
      gap: '1rem',
      justifyItems: 'center',
      alignItems: 'center',
  },
  }));

  export const Item = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      gap: '1rem',
    },
  }));

  export const ItemImage = styled('img')(({ theme }) => ({
    width: '162px',
    objectFit: 'cover',
    height: '162px',  
  }));

  export const RightArrowDiv = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      transform: `rotate(90deg)`,
      width: '116px',
    }, 
  }));

  export const RightArrowImage = styled(RightArrow)(({ theme }) => ({
  }));